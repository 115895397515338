@import "./variable.scss";

html, body {
  font-family: Amadeus, sans-serif !important;
  font-weight: 100;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}
.container {
  @media screen and (min-width: 1200px) {
    max-width: 1140px;
    max-width: none;
    width: calc(100% - 70px);
  }
}
.pointer {
  cursor: pointer;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
      -webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}