.home-slider {
	padding: 40px 0 0;
	.slider-image-comtainer {
		padding: 0 15px;
		img {
			width: 100%;
			cursor: pointer;
			opacity: 0.7;
		}
		.slider-image {
			position: relative;
			&.active {
				&:after {
					display: block;
				}
				img {
					opacity: 1;
				}
			}
		}
	}
	.slick-slider {
		margin-bottom: 40px;
		.slick-next,
		.slick-prev {
			width: 40px;
			height: 40px;
			z-index: 9;
			&:before {
				display: none;
			}
		}
		.slick-next {
			right: 0;
			background: url('../../images/arrow-right-gray.svg') center no-repeat;
			background-size: contain;
		}
		.slick-prev {
			left: 0;
			background: url('../../images/arrow-left-gray.svg') center no-repeat;
			background-size: contain;
		}
		.slick-list {
			margin: 0 5px;
		}
	}

	.testimonial-section {
	  text-align: center;
	  padding: 40px 0 0;
    color: #0072BC;
    margin-bottom: 5px;
	  h3 {
      // min-height: 106px;
			margin-bottom: 15px;
			padding: 0px 5%;
	  	&.active {
	  		display: block;
	  	}
	  }
	}
}