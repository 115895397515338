.lv1-content {
  .title {
    color: var(--color-main);
    font-size: 20px;
    display: none;
  }

  .tab-header {
    display: flex;
    border-bottom: 1px solid #E7E7E9;
    height: 40px;

    &__video-description,
    &__resources,
    &__quiz {
      padding: 10px;
      height: 100%;
      background-color: #C7C9CB;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #939597;
      width: 140px;
      cursor: pointer;

      &.active {
        background-color: var(--color-main);
        color: white;
      }
    }

    &__resources {
      background-color: #E0E1E3;
    }
  }

  .tab-content {
    &__video-description {
      font-size: 14px;
      color: rgba(0,0,0,0.5);
    }

    &__video-description {
      p {
        margin-bottom: 5px;
      }
      a {
        font-size: 14px;
        color: rgba(0,0,0,0.5);
        text-decoration: underline;
      }
    }

    &__quiz {
      font-size: 14px;
      color: rgba(0,0,0,0.5);
      h4 {
        font-size: 18px;
      }
      ul {
        padding-left: 15px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .title {
      display: block;
    }

    .tab-header {
      display: flex;
      border-bottom: 1px solid #E7E7E9;
      height: 60px;
  
      &__video-description,
      &__resources,
      &__quiz {
        width: auto;
        flex: 1;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}