.home-banner {
	padding-top: 25%;
	background: url('../../images/home-banner.png') center no-repeat;
	background-size: cover;
	.main-home-banner {
		padding-bottom: 40px;
		color: #fff;
		h1 {
			font-size: 65px;
		}
		h3 {
			font-size: 32px;
		}
	}
	.icon-arrow{
		width: 45px;
		margin-top: 40px;
		cursor: pointer;
	  box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
	  animation: pulse 2s infinite;
	  border-radius: 50%;
	}
}