// define color variable
:root {
  --color-main: #0072BC;
}

// define margin class

$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .m-#{str-slice($side, 0, 1)}-#{$space}p {
      margin-#{$side}: #{$space}% !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space}p {
      padding-#{$side}: #{$space}% !important;
    }
  }
}

@font-face {
  font-family: Amadeus-Bold;
  src: url("/fonts/Amadeus-Bold.otf") format("opentype");
}

@font-face {
  font-family: Amadeus;
  src: url("/fonts/Amadeus-Regular.otf") format("opentype");
}

@font-face {
  font-family: Amadeus;
  font-weight: 100;
  src: url("/fonts/Amadeus-Thin.otf") format("opentype");
}

@font-face {
  font-family: Amadeus;
  font-weight: 200;
  src: url("/fonts/Amadeus-Light.otf") format("opentype");
}

@font-face {
  font-family: Amadeus-Thin;
  font-weight: 100;
  src: url("/fonts/Amadeus-Thin.otf") format("opentype");
}

@font-face {
  font-family: Amadeus-Light;
  font-weight: 200;
  src: url("/fonts/Amadeus-Light.otf") format("opentype");
}