.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #e0e1e3;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
    > div {
      height: 100%;
      position: relative;
      > svg {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
      }
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .absolute-fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 100%;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;

    .play-icon {
      width: 25px;
      height: 25px;
      margin-left: 3px;
      margin-top: 2px;
    }

    .pause-icon {
      width: 25px;
      height: 25px;
    }

  }

  &:hover .button-container {
    visibility: visible;
  }
}

.video-setting {
  display: flex;
  padding: 20px;
  background-color: #E0E1E3;
  align-items: center;
  font-size: 12px;
  color: rgba(0,0,0,0.4);

  &__auto-play {
    margin-right: 20px;
    display: flex;
    align-items: center;
    

    &__switch {
      margin-left: 10px;
      position: relative;
      width: 30px;
      height: 10px;
      background-color: #C7C9CB;
    }

    &__thumb {
      position: absolute;
      background-color: #96999C;
      width: 14px;
      height: 14px;
      top: -2px;
      left: -2px;

      &.active {
        background-color: var(--color-main);
        top: -2px;
        right: -2px;
        left: auto;
      }
    }
  }

  &__speed {
    select {
      margin-left: 10px;
      border: none;
      background-color: transparent;
      outline: none;
      color: var(--color-main);
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

video::-webkit-media-controls-fullscreen-button
{
  display: none !important;
}