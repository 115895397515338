.survey-container {
  width: 100%;
  margin-top: 20px;

  p {
    font-size: 20px;
    margin-top: 10px;
  }

  textarea {
    width: 50%;
    height: 60px;
    outline: none;
    background-color: white;
    margin-left: 25%;
  }

  .checkbox-container {

    &__wrapper {
      padding: 0px 60px;
  
      @media screen and (max-width: 768px) {
        padding: 0px 10px;
      }
    }

    font-size: 0.8em;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        display: block;
        text-align: center;
        margin-top: 5px;
        height: 40px;
        font-size: 0.7em;
      }
    }
  }
  
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid white;
    margin-right: 10px;

    &.selected {
      background-color: var(--color-main);
    }
  }
}