

.level1-instructions {
  .amadeus-logo {
    background-color: var(--color-main);
    padding: 45px 25px;
    img {
      width: 40%;
      height: auto;
      margin: auto;
      display: block;
    }
  }

  h3 {
    // margin-top: 50px;
    color: var(--color-main);
    font-weight: 400;
    font-size: 24px;
  }
  .lessons, .quizzes {
    color: var(--color-main);
  }
  .quizzes {
    margin-left: 40px;
  }
  p {
    margin-top: 18px;
    font-weight: 300;
    font-size: 20px;
  }
  .description {
    font-size: 18px;
  }
  button {
    background-color: var(--color-main);
    color: white;
    border-radius: 20px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px 15px;
    cursor: pointer;
    border: none;
    outline: none;
  }
  .line {
    height: 2px;
    background-color: rgba(0,0,0,0.3);
    margin: 20px 0px;
  }

  img.avatar {
    width: 100%;
    height: auto;
  }

  h5 {
    font-weight: 300;
    font-size: 20px;
    color: var(--color-main);
  }

  .professor {
    &__name {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 5px;
      stroke-width: 0.5px;
      -webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: var(--color-main);
    }
    &__title {
      font-size: 14px;
    }
    &__description {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}