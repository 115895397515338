.overview-section {
	margin-top: 60px;
	.heading {
		color: #0072BC;
		margin-bottom: 20px;
		font-weight: 100;
	}
	p {
		color: #58595B;
	}
	.overview {
		font-size: 22px;
	}
	.qa-section {
		margin-top: 40px;
		.qa-item {
			.item-toggle {
		    font-size: 20px;
		    font-weight: 100;
				display: block;
		    width: 100%;
		    padding: 20px 65px 20px 30px;
		    color: #fff;
		    background: #0072BC;
		    border: none;
		    border-bottom: 1px solid #E6E7E8;
		    text-align: left;
		    cursor: pointer;
		    outline: 0;
		    position: relative;
				&:after {
					background: url('../../images/minus-white.png') center no-repeat;
					background-size: contain;
					content: '';
					display: block;
					width: 30px;
			    height: 30px;
			    position: absolute;
			    top: calc(50% - 15px);
					right: 30px;
				}
				&.collapsed{
					&:after {
						background: url('../../images/plus-white.png') center no-repeat;
						background-size: contain;
					}
				}
			}
			.item-body {
				background: #E6E7E8;
				color: #0072BC;
				padding: 10px 30px;
				p {
					color: #0072BC;
					margin-bottom: 0;
				}
				ul {
					padding: 0;
					margin: 0;
					li {
						list-style: none;
						&.sub-content {
							margin-left: 30px;
							&:before {
								content: '•';
								font-size: 18px;
								margin-right: 15px;
							}

							&.tick {
								margin-left: 15px;
							}
							&.tick:before {
								content: '✓';
								margin-right: 15px;
							}
						}
					}
				}
			}
		}
	}
}