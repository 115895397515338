.login-page {

  .footer-copyright {
    position: absolute;
    bottom: 30px;
    left: 60px;
    right: 60px;
    color: white;
    opacity: 0.4;

    a {
      float: right;
      color: white;
    }
  }

  .main-content {
    margin-top: 25vh;
    position: relative;

    @media screen and (min-height: 900px) {
      margin-top: 20vh;
    }

    .heading {
      color: white;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      left: 0px;
      bottom: 0px;

      h1 {
        font-size: 50px;
        font-weight: 100;
      }
      h3 {
        font-size: 30px;
        font-weight: 100;
      }

      h4 {
        color: rgba(255,255,255,0.7);
      }

      ul {
        width: 100%;
        padding-left: 25px;
        font-size: 20px;
        font-weight: 100;
        color: rgba(255,255,255,0.85);
      }

      @media screen and (max-width: 480px) {
        position: relative;
        text-align: center;
        h1 {
          font-size: 25px;
          margin-bottom: 5px;
          margin-top: 5px;
        }
        h3 {
          font-size: 15px;
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }
    }

    .register-now {
      text-decoration: none;
      color: var(--color-main);
      display: block;
      width: 320px;
      padding: 20px 40px;
      background-color: white;
      margin-top: 20px;
      text-align: center;
    }
  }

  @media screen and (max-width: 480px) {
    &.background-container {
      background-position-x: 40%;
      background-position-y: 20%;
    }

    .footer-copyright {
      left: 0px;
      right: 0px;
      bottom: 10px;
      text-align: center;
    }

    .main-content {
      margin-top: 7vh;

      .heading {
        margin-bottom: 20px;
        
      }

      .login-form form,
      .register-now {
        width: 100%;
      }
    }
  }
}

.login-form form,
.register-form,
.forgot-password-form {
  width: 320px;
  background-color: white;
  padding: 40px;

  h4, h5 {
    color: var(--color-main);
  }
  input {
    background-color: #F1F2F2;
    display: block;
    width: 100%;
    margin-top: 15px;
    padding: 5px;
    border: none;
    outline: none;
    border: 1px solid rgba(0,0,0,0.05);
    font-weight: 100;
    &::placeholder {
      color: #BCBEC0;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: #BCBEC0;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
     color: #BCBEC0;
    }
  }
  select {
    background-color: #F1F2F2;
    display: block;
    width: 100%;
    margin-top: 15px;
    padding: 7px 5px;
    border: none;
    outline: none;
    border: 1px solid rgba(0,0,0,0.05);
    font-weight: 100;
  }
  p {
    margin-top: 5px;
    color: var(--color-main);
    a {
      /* color: #00AEEF; */
      color: #00c8ff;
    }
  }
  input[type="submit"] {
    background-color: var(--color-main);
    color: white;
    border-radius: 20px;
    margin: auto;
    margin-top: 30px;
    font-size: 16px;
    padding: 5px 15px;
    width: auto;
    min-width: 50%;
    cursor: pointer;
  }

  label {
    display: flex;
    margin-top: 30px;
    align-items: flex-start;
    font-size: 12px;

    a {
      text-decoration: none;
      color: black;
    }
  }

  input[type="checkbox"] {
    width: auto;
    margin-top: 0px;
    margin-right: 15px;
    transform: translateY(6px);
    -webkit-appearance: none;
    background-color: #FFFFFF;
    border: 1px solid #ced4da;
    box-shadow: none;
    padding: 7px;
    border-radius: 0;
    position: relative;
    &:checked {
      &:after {
        content: '\2714';
        font-size: 14px;
        position: absolute;
        top: -3px;
        left: 2px;
        color: #0072BC;
      }
    }
  }

  .close {
    position: absolute;
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    top: 20px;
    right: 20px;
    border: 1px solid grey;
    text-align: center;
    padding-top: 4px;
    font-size: 18px;
    font-weight: 100;
  }

  .close-right {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 1px solid grey;
    text-align: center;
    font-size: 18px;
    font-weight: 100;
    float: right;
    transform: translate(15px, -15px);

    &:hover {
      border: 1px solid black;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
.register-form {
  padding: 25px;
}

.close-icon {
  position: absolute;
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  top: 20px;
  right: 20px;
  border: 1px solid grey;
  text-align: center;
  padding-top: 4px;
  font-size: 18px;
  font-weight: 100;
}