.certificate-2-container {
	margin: 0 auto;
	padding: 0 15px;
	color: #0072BC;
	font-size: 26px;
	font-weight: 400;
	width: 100%;

	strong {
		font-weight: bold;
	}
	* {
		margin-bottom: 0;
		line-height: 1;
	}
	h1 {
		font-size: 70px;
		font-family: Amadeus-Bold;
		// -webkit-text-stroke-width: 2px;
		// -webkit-text-stroke-color: #0072BC;
		// letter-spacing: 0.1em;
		margin-bottom: 10px;
	}
	h2 {
		font-size: 48px;
		font-family: Amadeus-Bold;
		// -webkit-text-stroke-width: 2px;
		// -webkit-text-stroke-color: #0072BC;
		// letter-spacing: 0.1em;
	}
	h3 {
		font-size: 32px;
		margin-bottom: 25px;
		font-family: Amadeus-Bold;
		// -webkit-text-stroke-width: 2px;
		// -webkit-text-stroke-color: #0072BC;
		// letter-spacing: 0.1em;
	}
	img {
		max-width: 100%;
	}
	.certificate-left {
		width: 130px;
		height: 100vh;
		background: url('../../images/bg-vertical.png') bottom center no-repeat;
		background-size: cover;
		position: relative;
		.certificate-badge {
			width: 220px;
			position: absolute;
			top: 40px;
			left: 20px;
			max-width: none;
		}
	}
	.certificate-right {
		width: calc(100% - 130px);
		min-height: 100px;
	}
	.certificate-main {
		padding: 70px 100px;
		text-align: center;
		height: 100vh;
		display: flex;
		flex-direction: column;

		&__content {
			flex: 1;
		}

		.member-name {
			margin: 100px 0 25px 0;
			border-bottom: 1px solid #0072BC;
	    padding-bottom: 25px;
		  width: calc(100% - 50px);
		  display: inline-block;
		}
		.signature-section {
			margin: 0 -40px;
    	margin-top: 40px;
			> div {
				align-items: flex-end;
		    display: flex;
		    width: 40%;
		    padding: 0 15px;
		    &.signature-center {
					width: 20%;
					display: flex;
					justify-content: center;
					img {
						max-width: 140px;
						
					}
		    }
			}
			.signature-left,
			.signature-right {
				> div {
					width: 100%;
				}
			}
			.text-small {
				font-size: 12px;
			}
		}
	}
}