.places-section {
	margin-top: 40px;
	padding: 20px 0;
	border-top: 1px solid #00BDF2;
	border-bottom: 1px solid #00BDF2;
	.heading {
		color: #0072BC;
		margin-bottom: 20px;
		font-weight: 100;
	}
	.tbc-text {
		font-size: 20px;
		font-weight: 100;
		margin-bottom: 3px;
	}
	img {
		max-width: 100%;
		width: 100%;
	}

	.places-items {
		.active {
			-webkit-box-shadow: 10px 10px 26px 0px rgba(0,0,0,0.75);
			-moz-box-shadow: 10px 10px 26px 0px rgba(0,0,0,0.75);
			box-shadow: 10px 10px 26px 0px rgba(0,0,0,0.75);
		}
		.inactive {
			opacity: 0.8;
		}
	}

	.places-item {
		cursor: pointer;
		&.unselected {
			filter: grayscale(1);
		}
		.item-top {
			background: #0072BC;
			display: block;
			text-align: center;
			padding: 10px;
			color: #fff;
			> * {
				margin: 0;
			}
		}
		.item-content{
			position: relative;
			.text {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				padding: 15px;
				text-align: center;
				display: flex;
  			justify-content: center;
				p {
					color: #fff;
					margin: 0;
					align-self: center;
				}
			}
			.overlay {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
	
}