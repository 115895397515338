.app-header {
  justify-content: space-between;
  display: flex;

  .site-name-left {
    color: white;
    display: flex;
    font-size: 25px;
  }

  .logo-right {
    display: flex;
    align-items: center;

    .btn-login {
      text-decoration: none;
      display: inline-block;
      background: #fff;
      color: #0072BC;
      padding: 5px 30px;
      border-radius: 20px;
      margin-right: 40px;
      font-weight: 400;
      &.blue {
        background: #0072BC;
        color: #fff;
      }
    }

    img {
      height: 18px;
      width: auto;
    }

    &__home {
      margin-left: 30px;
    }
  }

  @media screen and (max-width: 480px) {
    .site-name-left {
      display: none;
    }
    .logo-right {
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }

}