.lv1-side-bar {
  padding: 30px;
  padding-right: 15px;
  padding-left: 50px;
  font-weight: 100;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__intro {
    color: rgba(255,255,255,0.8);
    background-color: var(--color-main);
    padding: 10px 15px;
    margin-bottom: 20px;
    cursor: pointer;
    h3 {
      margin: 0px;
      font-size: 18px;
    }
  }

  &__progress-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;

    .milestone {
      display: flex;
      align-items: center;
      position: relative;

      &__circle {
        width: 20px;
        height: 20px;
        background-color: rgba(0,0,0,0.05);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #A7A9AC;

        &.focused {
          background-color: var(--color-main);
        }

        &__wrapper {
          height: 100%;
          display: flex;
          width: 20px;
          margin-right: 10px;
          flex-direction: column;
        }
      }

      &__title {
        display: flex;
        flex: 1;
        &:hover, &.focused {
          color: var(--color-main);
        }
      }

      &__tick {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }

      &__vertical-line {
        min-height: 3vh;
        width: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          width: 2px;
          background-color: #A7A9AC;
          height: 3vh;

          &.blue {
            background-color: var(--color-main);
          }
        }

        &__upper, &__lower {
          display: flex;
          flex: 1;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            width: 2px;
            background-color: #A7A9AC;
            height: 100%;

            &.blue {
              background-color: var(--color-main);
            }
          }
        }
      }
    }
  }

  &__footer {
    font-size: 14px;
  }

  &__toggle-icon {
    display: none;
    transform: translateX(-10px);
    img {
      text-align: center;
      width: 20px;
      height: 20px;
      object-fit: contain;
      // margin-left: 10px;
    }
    span {
      color: var(--color-main);
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 768px) {

    &__footer {
      display: none;
    }

    &__toggle-icon {
      display: block;
    }

    &__progress-container {

      .milestone {

        &__title {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 15px;

    &__footer {
      display: none;
    }

    &__toggle-icon {
      display: block;
      transform: none;
      margin-left: 10px;
      span {
        display: none;
      }
    }

    &__progress-container {
  
      .milestone {
        justify-content: center;

        &__circle {

          &__wrapper {
            margin-right: 0px;
          }
        }

        &__title {
          display: none;
        }

        &__vertical-line {
          // transform: translateX(19px);
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .level-1-content__progress.slide {
    .lv1-side-bar {
      &__progress-container {
  
        .milestone {
          justify-content: flex-start;
  
          &__circle {
  
            &__wrapper {
              margin-right: 10px;
            }
          }
  
          &__title {
            display: block;
          }
  
          &__vertical-line {
            width: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .level-1-content__progress.slide {
    .lv1-side-bar {
      &__progress-container {
  
        .milestone {
          justify-content: flex-start;
  
          &__circle {
  
            &__wrapper {
              margin-right: 10px;
            }
          }
  
          &__title {
            display: block;
          }
  
          &__vertical-line {
            width: 20px;
          }
        }
      }
    }
  }
}
