.benefit-section {
	padding: 40px 0;
	.benefit-heading {
		text-align: center;
		margin-bottom: 25px;
		h3{
			color: #0072BC;
			display: inline-block;
			position: relative;
			padding-bottom: 5px;
			&:after{
				content: '';
				display: block;
				width: calc(100% - 60px);
				border-bottom: 2px solid #E6E7E8;
				position: absolute;
				left: 30px;
				bottom: 0;
			}
		}
	}
	.benefit-item {
		text-align: center;
		@media screen and (max-width: 575px) {
	    margin-bottom: 30px;
	  }
		.benefit-icon {
			display: inline-block;
			width: 90px;
			height: 90px;
			margin-bottom: 10px;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
		h4 {
			color: #0072BC;
		}
		p {
			color: #231F20;
		}
	}
}