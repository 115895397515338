.main {
	position: relative;
	.banner-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: 30px;
		padding-bottom: 30px;
	}
}
.privacy-policy-page {
	.collapse-box {
		a[data-toggle="collapse"] {
			display: block;
			color: #000;
			text-decoration: none;
			position: relative;
			padding-right: 35px;
			&:after {
				content: '\2014';
				display: block;
				width: 32px;
				height: 32px;
				position: absolute;
				top: 0;
				right: 0;
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				margin-top: 5px;
			}
			&.collapsed {
				&:after {
					content: '\002B';
					font-size: 46px;
					margin-top: -11px;
					font-weight: 100;
				}
			}
		}
	}
}