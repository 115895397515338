.main {
	position: relative;
	.home-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: 30px;
		padding-bottom: 30px;
		z-index: 9999;
  }
}