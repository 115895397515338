.level-introduction {
	padding: 90px 0;
	@media screen and (max-width: 575px) {
    .level-item {
    	margin-bottom: 30px;
    }
  }
	.level-heading {
		color: #0072BC;
		text-align: center;
	}
	img {
		max-width: 100%;
	}
	.level-box {
		&:hover {
			.level-top {
				.level-detail {
					display: block;
				}
			}
		}
		.level-top {
			position: relative;

			img {
				width: 100%;
			}

			.level-detail {
				display: none;
				position: absolute;
				padding: 15px;
				background: rgba(0, 114, 188, 0.8);
				bottom: 0;
				left: 0;
				color: #fff;
				max-height: 100%;
    		overflow: hidden;
    		min-height: 285px;
    		@media screen and (max-width: 900px) {
    			overflow: auto;
    			min-height: auto;
    		}
    		ul {
    			padding-left: 20px;
    			li {
    				margin-bottom: 5px;
    				&:last-child{
							margin-bottom: 0;
    				}
    			}
    		}
    		> *:last-child {
    			margin-bottom: 0;
    		}
			}
		}
		.level-bottom {
			a {
				font-size: 1.75rem;
				color: #fff;
				display: block;
				padding: 15px;
				padding-right: 60px;
				position: relative;
				background: #0072BC;
				text-decoration: none;
				&:after{
					content: '';
					display: block;
					width: 28px;
					height: 28px;
					background: url('../../images/arrow-right-white.png') center no-repeat;
					background-size: contain;
					position: absolute;
					right: 15px;
					top: 22px;
					box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
				  animation: pulse 1.5s infinite;
				  border-radius: 50%;
				}
			}
		}
	}
}