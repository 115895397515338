.home-banner-slider {
	position: relative;
	.banner-slide {
		padding-top: 10%;
		padding-left: 3%;
		padding-bottom: 100px;
		position: relative;
		background-image: linear-gradient(transparent, rgba(10, 10, 9, 0.8));
		.banner-slider-image {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-size: cover;
			z-index: -1;
		}
		.main-banner-slider {
			color: #fff;
	    height: 300px;
  		overflow: hidden;
  		padding-left: 60px;
			padding-right: 60px;
			
			h1 {
				font-size: 65px;
			}
			h3 {
				font-size: 28px;
			}

			@media screen and (max-width: 1200px) {
				height: 250px;
				padding-top: 20px;

				h1 {
					font-size: 36px;
				}
				h3 {
					font-size: 20px;
				}
				p {
					font-size: 16px;
				}
			}

			@media screen and (max-width: 480px) {
				height: 200px;
				padding-top: 20px;
				h1 {
					font-size: 20px;
				}
				h3 {
					font-size: 12px;
				}
				p {
					font-size: 10px;
				}
			}
		}
	}
	.arrow-down {
		position: absolute;
		bottom: 40px;
		width: 100%;
	}
	.icon-arrow{
		width: 45px;
		cursor: pointer;
	  box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
	  animation: pulse 2s infinite;
	  border-radius: 50%;
	}
	.slick-slider {
		.slick-prev,
		.slick-next {
			width: 40px;
	    height: 40px;
	    z-index: 9;
	    &:before {
	    	display: none;
	    }
		}
		.slick-prev {
			left: 50px;
	    background: url('../../images/arrow-left-white-big.svg') center no-repeat;
	    background-size: contain;
		}
		.slick-next {
			right: 50px;
	    background: url('../../images/arrow-right-white-big.svg') center no-repeat;
	    background-size: contain;
		}
	}
}