.register-section {
	margin-top: 40px;
	color: #0072BC;

	.heading {
		color: #0072BC;
		margin-bottom: 20px;
		font-weight: 100;

		&.small-space {
			margin-bottom: 0px;

			a {
				color: #0072BC;
				text-decoration: underline;
				font-size: 28px;
			}
		}
	}
	h2 {
		font-weight: 100;
	}
	.level-register-form {
		.note {
			color: #E51B24;
		}
		input {
			color: #000;
			background: #F1F2F2;
			outline: none;
			border-radius: 0;
			font-weight: 100;
		}
		select {
			color: #000;
			background: #F1F2F2;
			outline: none;
			border-radius: 0;
			font-weight: 100;
		}
		.form-group {
			position: relative;
			label {
				display: block;
			}
			textarea,
			input,
			select {
				width: 100%;
				&::placeholder {
		      color: #BCBEC0;
		      opacity: 1; /* Firefox */
		    }

		    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
		     color: #BCBEC0;
		    }

		    &::-ms-input-placeholder { /* Microsoft Edge */
		     color: #BCBEC0;
		    }
				&:focus,
				&:valid {
					+ .placeholder {
						display: none;
					}
				}
			}
			.placeholder {
				position: absolute;
				width: calc(100% - 2px);
				height: calc(100% - 2px);
				top: 1px;
				left: 1px;
				padding: .375rem .75rem;
				color: #BCBEC0;
				background: #F1F2F2;
				cursor: text;
				overflow: hidden;
			}
		}
		.form-check {
			position: relative;
			padding-left: 30px;
			input[type="checkbox"] {
				-webkit-appearance: none;
				background-color: #F1F2F2;
				border: 1px solid #ced4da;
				box-shadow: none;
				padding: 10px;
				border-radius: 0;
				display: inline-block;
				position: absolute;
				left: 0;
				margin-left: 0;
				&:checked {
					border-color: #0072BC;
					&:after {
						content: '\2714';
						font-size: 14px;
						position: absolute;
						top: 0px;
						left: 5px;
						color: #0072BC;
					}
				}
			}
			label {
				color: #58595B;
			}
		}
		button {
			border: none;
	    padding: 10px 45px;
	    text-align: center;
	    background: #0072BC;
	    color: #fff;
	    border-radius: 22px;
		}
	}
}