.footer {
	&.footer-blue {
		background: #0072BC;
		color: #fff;

		a {
			color: white;
		}
	}
	.main-footer {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	

	a {
		color: black;
		&:hover span {
			text-decoration: underline;
		}
	}

	.footer-line {
		background: #0072BC;
    height: 20px;
		border-top: 3px solid #00BDF2;
	}
}