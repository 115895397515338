.level-1-page {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    background-color: var(--color-main);
    display: flex;
    flex-direction: column;
    .app-container {
      padding: 30px 50px;
      padding-bottom: 0;
    }

    &__site {
      font-weight: 200;
      font-size: 22px;
      color: rgba(255,255,255,0.7);
    }
    &__title {
      font-weight: 100;
      font-size: 34px;
      color: white;
    }

    @media screen and (max-width: 480px) {
      text-align: center;
      &__site {
        margin-top: 10px;
      }
    }
  }
}

.level-1-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;

  &__progress {
    background-color: #EDEEEF;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 5;

    flex-grow: 1;

    transition:width 0.25s;
    -webkit-transition:width .25s;
    -moz-transition: width 0.25s;
  }

  &__detail {
    position: relative;
    .absolute-fill-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.4);
    }
  }

  .footer-privacy-links {
    position: absolute;
    bottom: 30px;
    right: 60px;
    color: #212529;
    font-size: 14px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
}