.lv1-quiz {
  width: 100%;
  background-color: #EDEEEF;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 360px;

  .quiz-message-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    &.open {
      display: block;
    }
    .quiz-message-modal {
      opacity: 1;
      box-shadow: 0px 0px 10px #393939;
      button {
        border: none;
      }
    }
  }

  &.message-middle {
    height: 150px;
    justify-content: center;
    align-items: center;
  }

  &__q-a {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;

    &__quiz-number {
      font-size: 12px;
      font-weight: 100;

      strong {
        font-weight: bold;
      }
    }

    &__question {
      color: rgba(0,0,0,0.5);
      font-size: 18px;
    }

    &__answer {
      color: rgba(0,0,0,0.5);
      font-size: 15px;
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: #E0E1E3;
      border: 1px solid white;

      input[type="checkbox"] {
        -webkit-appearance: none;
        margin-right: 10px;
        background-color: #FFFFFF;
        border: 1px solid #BCBEC0;
        box-shadow: none;
        padding: 9px;
        border-radius: 0;
        outline: none;
        position: relative;

        &:checked {
          &:after {
            content: '\2714';
            font-size: 17px;
            position: absolute;
            top: -3px;
            left: 2px;
            color: #0072BC;
          }
        }
      }
    }
  }

  &__btns {
    display: flex;
    background-color: #E0E1E3;
    height: 50px;
    justify-content: center;
    align-items: center;
    position: relative;

    .btn {
      height: 30px;
      background-color: var(--color-main);
      color: white;
      display: flex;
      border-radius: 0px;
      justify-content: center;
      align-items: center;
      &.btn-submit {
        position: absolute;
        right: 20px;
      }
      &.disabled {
        color: rgba(255,255,255,0.5);
        pointer-events: none;
      }

      &.active {
        color: white;
      }

      &:hover {
        color: white;
      }
    }
  }
}


.quiz-message-modal {
  background-color: var(--color-main);
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 30px;
  color: white;
  max-width: 100%;
  position: relative;

  &.h-130 {
    height: 130% !important;
  }

  h1 {
    font-size: 60px;
    font-weight: 100;
    font-style: italic;
  }

  button {
    color: #8CB7DB;
    padding: 10px 40px;
    font-size: 14px;
    margin-top: 30px;

    &:hover {
      color: var(--color-main);
      cursor: pointer;
    }
  }

  &-inner-content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px 30px;
    color: white;

    h1 {
      font-style: normal;
    }

    button {
      color: white;
      background-color: var(--color-main);
      padding: 10px 40px;
      font-size: 14px;
      margin-top: 30px;
      border: 1px solid white;
  
      &:hover {
        color: var(--color-main);
        background-color: white;
        cursor: pointer;
      }
    }
  }

  .white-btn {
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: white;
    color: var(--color-main);

    a {
      color: var(--color-main);
    }
  }

  &.big {
    width: 90vw;
  }

  h3 {
    font-size: 30px;
    font-weight: 100;
  }

  
}