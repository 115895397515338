.banner {
	padding-top: 27%;
	background-size: cover;
	.main-banner {
		padding-bottom: 40px;
		color: #fff;
	}

	h1 {
		font-size: 50px;
		font-weight: 100;
	}

	@media screen and (max-width: 480px) {
		h1 {
			font-size: 30px;
		}
	}
}